import React from 'react';
import { Elements, LinkResolver } from 'prismic-reactjs';
import { Description } from '../../components/sections/Faq/faq.styles';

const linkResolver: LinkResolver = () => '/';

const propsWithUniqueKey = (props: object, key: any) =>
  Object.assign(props || {}, { key });

// TODO: type of this function
export const faqHtmlSerializer = (
  type: Elements,
  element: any,
  content: string,
  children: any[],
  key: string
): any => {
  let props = {};

  switch (type) {
    case Elements.paragraph:
      return (
        <Description {...propsWithUniqueKey(props, key)}>
          {children}
        </Description>
      );

    case Elements.hyperlink:
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : {};
      const relAttr = element.data.target ? { rel: 'noreferrer noopener' } : {};

      const href =
        element.data.link_type === 'Web' &&
        element.data.url.includes('https://qodeca.com/')
          ? new URL(element.data.url).pathname
          : element.data.url || linkResolver(element.data);

      props = Object.assign(
        {
          href,
          className: 'faq__link',
        },
        targetAttr,
        relAttr
      );

      return <a {...propsWithUniqueKey(props, key)}>{children}</a>;

    default:
      return null;
  }
};
