import { IFaqItem, IFaqProps } from './interfaces';
import { ItemsWrapper, Section, Title, Wrapper } from './faq.styles';

import { useFaq } from './faq.hooks';
import { FaqItem } from './FaqItem/FaqItem';

const getFaqId = (index: number): string => `faq-${index}`;

export const Faq = ({ title, items, id, titleAs }: IFaqProps): JSX.Element => {
  const { answersRef, answerRef, handleExpand, expandedItem } = useFaq();

  return (
    <Section id={id && id}>
      <Wrapper>
        <Title as={titleAs}>{title}</Title>

        <ItemsWrapper>
          {items.map((faqItem: IFaqItem, i: number) => {
            const faqId = getFaqId(i);

            return (
              <FaqItem
                key={faqId}
                answersRefs={answersRef}
                expanded={expandedItem === faqId}
                faqId={faqId}
                id={i}
                handleClick={handleExpand}
                faqItem={faqItem}
              />
            );
          })}
        </ItemsWrapper>
      </Wrapper>
    </Section>
  );
};
