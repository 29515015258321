import { useCallback, useRef, useState } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';

export const useFaq = () => {
  const [expandedItem, setExpandedItem] = useState<string>('');
  const { medium } = useWindowSize();
  const answersRef = useRef<Array<HTMLDivElement>>([]);
  const answerRef = useRef<HTMLDivElement>();

  const handleExpand = useCallback(
    (evt: any, faqId: string): void => {
      if (expandedItem !== faqId) {
        setExpandedItem(faqId);
        if (!medium) {
          scrollToExpanded(evt);
        }
      } else {
        setExpandedItem('');
      }
    },
    [expandedItem, medium]
  );

  const scrollToExpanded = (evt: any) => {
    let position = 0;
    if (
      answerRef.current &&
      answerRef.current.offsetTop < evt.target.offsetTop
    ) {
      position =
        evt.target.parentElement.offsetTop -
        (answerRef.current.parentElement?.offsetHeight || 0);
    } else {
      position =
        evt.target.parentElement.offsetTop -
        evt.target.parentElement.offsetHeight;
    }

    if (position > 0) {
      window.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    }

    answerRef.current = evt.target;
  };

  return {
    expandedItem,
    handleExpand,
    answerRef,
    answersRef,
  };
};
