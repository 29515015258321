import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { THEME } from '../../../common/consts/theme';
import { defaultContainerSize, mq } from '../../../styles/mixins';
import { Header2, ParagraphM } from '../../core/Typography/Typography';

export const Section = styled.section`
  padding: 4rem 0;

  ${mq.mobileMax(css`
    padding: 7.5rem 0;
  `)}
`;

export const Wrapper = styled.div`
  ${defaultContainerSize};
`;

export const Title = styled.h2`
  ${Header2};
  color: ${THEME.colors.black};
  margin: 0;
`;

export const ItemsWrapper = styled.div`
  margin-top: 2rem;

  ${mq.mobileMax(css`
    margin-top: 4rem;
  `)}
`;

export const Description = styled.p`
  ${ParagraphM};
  color: ${THEME.colors.dark_grey};
  margin: 0;
  padding: 0 0 2rem 0;
`;
