// @flow
import * as React from 'react';
import classNames from 'classnames';
import { RichText } from 'prismic-reactjs';
import { Answer, Question, Wrapper } from './faqItem.styles';
import { IFaqItem } from '../interfaces';
import { faqHtmlSerializer } from '../../../../utils/htmlSerializers/faqHtmlSerializer';

type Props = {
  id: number;
  faqId: string;
  expanded: boolean;
  handleClick: (evt: any, id: string) => void;
  faqItem: IFaqItem;
  answersRefs: React.RefObject<Array<HTMLDivElement>>;
};
export const FaqItem = ({
  expanded,
  handleClick,
  faqItem,
  faqId,
  id,
  answersRefs,
}: Props) => {
  return (
    <Wrapper expanded={expanded}>
      <Question onClick={(evt) => handleClick(evt, faqId)}>
        {RichText.asText(faqItem.question)}

        <svg
          width="19"
          height="11"
          viewBox="0 0 19 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L9.5 9.5L18 1" />
        </svg>
      </Question>

      <Answer
        style={{
          maxHeight: expanded
            ? `${answersRefs.current?.[id].scrollHeight}px`
            : '0',
        }}
        expanded={expanded}
        ref={(ref: HTMLDivElement) => (answersRefs.current![id] = ref)}
      >
        <RichText render={faqItem.answer} htmlSerializer={faqHtmlSerializer} />
      </Answer>
    </Wrapper>
  );
};
