import styled from '@emotion/styled';
import { THEME } from '../../../../common/consts/theme';
import { css } from '@emotion/react';
import { mq } from '../../../../styles/mixins';
import { ParagraphM } from '../../../core/Typography/Typography';

export const Wrapper = styled.div<{ expanded: boolean }>`
  border-bottom: 0.0625rem solid ${THEME.colors.grey};
  padding: 1rem 0;
  ${mq.mobileMax(css`
    padding: 0 4.375rem 0 3.438rem;
  `)}

  ${({ expanded }) =>
    expanded &&
    css`
      ${Question} {
        svg {
          transform: rotate(180deg);

          path {
            stroke: ${THEME.colors.accent};
          }
        }
      }
    `}
`;

export const Question = styled.p`
  ${ParagraphM};
  color: ${THEME.colors.core_black};
  line-height: 1.875rem;
  margin: 0;
  padding: 1.25rem 4rem 1.25rem 0;
  position: relative;
  cursor: pointer;
  display: block;
  user-select: none;

  ${mq.xLarge(css`
    font-size: 1.5rem;
    line-height: 2.25rem;
  `)}

  &:after {
    content: '';
    height: 2.875rem;
    width: 2.875rem;
    position: absolute;
    border: 0.0625rem solid ${THEME.colors.core_white};
    border-radius: 3rem;
    top: calc(50% - 1.5rem);
    right: 0;
    box-shadow: 0.5rem 0.5rem 1.5rem rgba(23, 0, 89, 0.29);
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }

    .svg {
      border-color: ${THEME.colors.core_white};
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
    border-radius: 3rem;
    position: absolute;
    border: 0.0625rem solid ${THEME.colors.grey};
    top: calc(50% - 1.5rem);
    right: 0;
    transition: transform 300ms ease-in-out, border-color 300ms ease-in-out;
    padding: 0.9375rem;

    path {
      stroke: ${THEME.colors.primary};
      transition: stroke 300ms ease-in-out;
    }
  }
`;

export const Answer = styled.div<{ expanded: boolean }>`
  max-height: 0;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  overflow: hidden;
  opacity: 0;

  ${({ expanded }) =>
    expanded &&
    css`
      opacity: 1;
    `}
`;
